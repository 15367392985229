import { IoCall, IoMail, IoMap } from 'react-icons/io5';

import Image from '../components/image';
import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScBlackContainer = styled.div`
  /* background: #666; */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12),
    inset 0 1px 2px rgba(0, 0, 0, 0.24);
`;

const ScMaxWidthContainer = styled.div`
  position: relative;
  max-width: 1280px;
  height: 100%;
  margin: auto;
  z-index: 100;
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Balance & Effizienz im Alltag" keywords={globals.keywords} />
    <ScBlackContainer>
      <ScMaxWidthContainer></ScMaxWidthContainer>
    </ScBlackContainer>
  </Layout>
);

export default IndexPage;
